import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`1/4 Jump Squats (Back) 4×6\\@70%1RM`}</p>
    <p>{`Single Leg Hamstring Curl 4×6/leg`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 500M Row`}</p>
    <p>{`3 rounds of:`}</p>
    <p>{`10-Back Squats (185/125)(RX+ 225/155)`}</p>
    <p>{`20-KBS’s (53/35)(RX+ 70/53)`}</p>
    <p>{`Cash Out: 500M Row`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      